import React, { useEffect, useState } from 'react';
import { collection_activity_item_data } from '../../data/collection_data';
import Link from 'next/link';
import Image from 'next/image';


import { useDispatch } from 'react-redux';
import { reviewModalShow, disputeModalShow } from '../../redux/counterSlice';

import dayjs from 'dayjs';
import Reply from '../../components/form/review/reply';
import ChatAdd from '../../components/form/chat';

import Pre from '../../components/code/pre';

import config from "../../utils/config.js";

import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';

import Avatar from "../../components/avatar";

import "lightgallery.js/dist/css/lightgallery.css";
import {
  LightgalleryProvider,
  LightgalleryItem
} from "react-lightgallery";

//Local Storage Session 
import { ReactSession } from 'react-client-session';


const Activity_item = ({order , setOrder, setTabsActive}) => {

	
	const dispatch = useDispatch();

	//React Local Session
	ReactSession.setStoreType("localStorage");

	function createMarkup(text) { return {__html: text}; };

	function onlyUnique(value, index, self) {
		return self.indexOf(value) === index;
	}

	const [userType, setUserType] = useState("");

	useEffect(() => {
		if(order.service.user._id == ReactSession.get("_id")){
		  //Earner details
		  setUserType("earner");
		}else if(order.user._id == ReactSession.get("_id")){
		  //Hirer Details
		  setUserType("hirer");
		}
	});

	

	const [filterData, setfilterData] = useState(
		collection_activity_item_data.map((item) => {
			const { category } = item;
			return category;
		})
	);

	const [inputText, setInputText] = useState('');

	const handleFilter = (category) => {
		setData(collection_activity_item_data.filter((item) => item.category === category));
	};
	const handleSubmit = (e) => {
		e.preventDefault();
		const newArray = collection_activity_item_data.filter((item) => {
			return item.title.toLowerCase().includes(inputText);
		});
		setData(newArray);
		setInputText('');
	};

	useEffect(() => {
		setfilterData(filterData.filter(onlyUnique));
	}, []);

	


	return (
		<>
		 {order&&
                [order]?.map((record) => {
                  const { id, code, service, user, details, date, deliveryDate, delivery, subTotal, serviceFee, amount, status } =
                    record;

                    const delivery_date = new Date(deliveryDate).toDateString();
                    const order_date = new Date(date).toDateString();

					return (
                		<>
						{/* <!-- Activity Tab --> */}

						{/*<Pre json={order} />*/}

						{/**/} 
						{order.activity&&
						<>
						{Object.values(order.activity).map((item, index) => {
							
							const i = Number(index+1);
							const { user, image, message, category, type, rating, title, section, delivery, updated } = item;
																			
							const dt = dayjs(updated);
							const formatted = dt.format("ddd MMMM D, h:mm a");

							
							var av_url;
							var av_user;
							var name;


							if(user == service.user._id){
								//Earner profile details
								av_url = service.user.image;
								av_user = service.user;
								name = service.user.firstName? service.user.firstName+" "+service.user.lastName : service.user.username;
							}else if(user == order.user._id){
								//Hirer profile details
								av_url = order.user.image;
								av_user = order.user;
								name = order.user.firstName? order.user.firstName+" "+order.user.lastName : order.user.username;
								
							}

							//console.log(user+" "+image_url );
							
							return (
									<>
									{section!="resolution" &&
									<>
									<a key={index} className=" flex items-center py-2 mb-8">
										<figure className="mr-5 self-start min-w-[50px]">
											<Avatar width="40" height="40" image={av_url} user={av_user}/>
										</figure>

										<div>
											
												<h3 className="font-display text-accent-dark mb-1 text-md font-semibold dark:text-white flex ">
													{name}  &nbsp;
													
													{type=="notice" &&   <span className="text-jacarta-700 dark:text-white">{message} &nbsp;</span> }
													{type=="review" || section=="resolution" &&  <span className="text-jacarta-700  dark:text-white">{title} &nbsp;</span> }
													
													<span className="text-jacarta-300 sp-1 text-sm mt-1 italic font-normal">{formatted}</span>


												
												</h3>


												{type=="message"  && 

												<div className="text-md html-text" dangerouslySetInnerHTML={createMarkup(message)} />
												}

												{type=="review"&& 
												<>
													<div className="pb-2">

														<div className="text-md html-text" dangerouslySetInnerHTML={createMarkup(message)} />

													</div>
													<div className="pt-4 pb-12">
														<div className="flex space-x-7 ">
															<span className="font-display min-w-[200px]">
																Communication
															</span>
															<Rating
																readOnly
																size="large"
																emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
																value={rating.communication}
															/>
															<span className="mt-1 block font-bold text-yellow">{rating.communication}</span>
														</div>
														<div className="flex space-x-7 ">
															<span className="font-display min-w-[200px]">
																Quality
															</span>
															<Rating
																readOnly
																size="large"
																emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
																value={rating.quality}
															/>
															<span className="mt-1 block font-bold text-yellow">{rating.quality}</span>
														</div>
														<div className="flex space-x-7 ">
															<span className="font-display min-w-[200px]">
																Recommend to a Friend?
															</span>
															<Rating
																readOnly
																size="large"
																emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
																value={rating.recommend}
															/>
															<span className="mt-1 block font-bold text-yellow">{rating.recommend}</span>
														</div>

													</div>
													
													

													{userType == "earner" && status =="reviewed" &&

														<Reply order={order} setOrder={setOrder}/>
													}
												</>
												}

												{section=="delivery"&& 
												<>

												{Object.values(record.delivery)
												.filter((item) => item.id === delivery)
												.map((item) => {
													const {id, user, message, type, section, files, updated } =item
													return (
														<>
															<div className="text-md html-text" dangerouslySetInnerHTML={createMarkup(message)} />

															{files&&<>
															<div className="w-full flex mt-4">
															<LightgalleryProvider>
																{Object.values(files)
																.map((file) => {
																	const {id, name } =file
																	return (
																		<>
																		
																		<LightgalleryItem src={config.IMAGE_URL+"/uploads/orders/"+record.id+"/"+name} subHtml={message} group="2">
																		<img  width={150} src={config.IMAGE_URL+"/uploads/orders/"+record.id+"/"+name}
																				className="text-md html-text mr-2 rounded-2lg" />
																		</LightgalleryItem>
																			




																		</>
																	);
																})}
															</LightgalleryProvider>
															</div>
															</>
															}
														</>
													);
												})}

												</>
												}

											
											
											

										</div>

										<div className="dark:border-jacarta-600 border-jacarta-100 ml-auto rounded-full  p-3  min-w-[100px]">
										
										</div>
									</a>
								</>
								}
							</>	
							);
						})}
						</>
						}
						
						{!order.requirements&&

							<a className=" flex items-center py-2">
								<figure className="mr-5 self-start min-w-[50px]">
									<img
										src={config.IMAGE_URL+"/images/icons/list.svg"}
										height={40}
										width={40}
										className="rounded-2lg mt-2"
									/>
								</figure>

								<div>
									<h3 className="font-display text-jacarta-700 mb-1 text-md font-semibold dark:text-white flex ">
										Send the requirements for your order &nbsp;
										
										
									</h3>

								</div>

								<div className="dark:border-jacarta-600 border-jacarta-100 ml-auto rounded-full  p-3  min-w-[100px]">
									<button onClick={() => setTabsActive(2)} className="px-6 py-1   border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent flex items-center justify-end rounded-full  bg-accent text-white transition-colors border-transparent focus:border-transparent dark:border-transparent">
									
										Send Requirements
									</button>
								</div>
							</a>

						}

						{order.status =="started"&&


							<ChatAdd order={order} setOrder={setOrder}/>
						}
						{order.status =="resolution"&&
						
							<div className="w-full text-center mt-16 mb-4"> 
								<span className="font-display text-lg  text-jacarta-300 ">This order is being resolved.</span>
							</div>
					
						}
						{order.status =="complete"&&
						
							<div className="w-full text-center mt-16 mb-4"> 
								<span className="font-display text-lg  text-jacarta-300 ">This order was completed.</span>
							</div>
						
						}
						
						{/*<Pre json={order.activity} />*/}
						
			</>
			);
		})}
		</>
	);
};

export default Activity_item;
