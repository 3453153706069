import React, { useState, useEffect, useMemo } from "react";
import dynamic from 'next/dynamic';


//MUI COMPONENTS
import Rating from '@mui/material/Rating';
import Checkbox from '@mui/material/Checkbox';
import Alert from '@mui/material/Alert';

//MUI ICONS
import StarIcon from '@mui/icons-material/Star';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';

//Formik
import { useFormik, FormikProvider } from 'formik';

//Axios
import axios from "axios";
import config from "../../../utils/config.js";

//Local Storage Session 
import { ReactSession } from 'react-client-session';

//Debugging Imports (Inactive but don't remove)
import Pre from '../../code/pre';


const ReviewAdd = ({order , setOrder, closeModal}) => {

    const Editor = dynamic(() => import('../../form/editor'), { ssr: false });
   

    let formik = {};
    const [warning, setWarning] = useState(false);
    const [warningMessage, setWarningMessage] = useState("");
    const [success, setSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    const [message, setRequirements] = useState("");
    
    const [comm, setComm] =useState(0);
    const [qual, setQual] =useState(0);
    const [rec, setRec] =useState(0);

    const [review, setReview] =useState("");


    const[complete, setComplete] =useState(false);


  
    //  AXIOS REQUEST                            
    const client = axios.create({ baseURL: `${config.API_URL}`});
    const auth = { headers: { Authorization: `Bearer ${config.API_KEY}`}};


    function createMarkup(text) { return {__html: text}; };

    const handleChange = (event) => {
        formik.values.agree = event.target.checked;
        setChecked(event.target.checked);
    };

    // New Activiy ID
    var activity_id ;
    var form = {};
    var review_form = {};

  
  formik = useFormik({
        initialValues: {

            order: order._id,
            user: order.user?._id,
            service: order.service?._id,
            earner: order.service?.user?._id,
            message:message,
            id: order.id,
            code: order.code

        },
        onSubmit: (values) => {

        if(!values.message){
            setSuccess(false);
            setWarning(true);
            setWarningMessage("Please leave a review comment.");

        }else{


            //RATING OBJECT
            let rating = {
                communication:comm,
                quality:qual,
                recommend:rec
            }


            // ORDER FORM DETAILS (UPDATE ACTIVITY & STATUS)
            form.id = values.id.toString();
            form.code = values.code;
            form.user = values.user;
            form.service = values.service;
            form.status = "complete";
          
            

            // ACTIVITY MAX ID
            let max = 0;
            if(order.activity){
                //Max ID
                max = Object.keys(order.activity).reduce(function(a, b){ return order.activity[a] > order.activity[b] ? a : b });

            }

            // ACTIVITY _ ADDED REVIEW
            activity_id = Number(max) + 1  ;
            const activity = {...order.activity?order.activity:{}, [activity_id] : {
                id : activity_id,
                user: ReactSession.get("_id"),
                message: values.message,
                rating: rating,
                type: "review", // notice, message
                section: "activity", // activity, requirements, delivery, resolution
                title: "reviewed this order",
                updated: new Date()
            }};



            form.activity = JSON.stringify(activity);

            
            // REVIEW FORM DETAILS
            review_form.id = values.id;
            review_form.date = new Date();
            review_form.order = order._id;
            review_form.user = values.user;
            review_form.service = values.service;
            review_form.earner = values.earner;
            review_form.rating = rating;
            review_form.message = values.message;
            review_form.status = "accepted";


            //alert(JSON.stringify(review_form, null, 2));



            // PUT ORDER :::::: API
            client.put(`/order/${ order?._id }`, form , auth)
            .then((response) => {
                
                setOrder(response.data);
                setComplete(true);
                ///alert("200: OK - CHECK DB" + JSON.stringify(order));
            })
            .catch(error => { console.error(error) });

          
            // POST REVIEW ::::: API
             client.post(`/review`, review_form , auth)
            .then((response) => {
                
                //alert("200: OK - CHECK DB" + JSON.stringify(review));
               
            })
            .catch(error => { console.error(error) });


            // USER FEEDBACK 
            setWarning(false);
            setSuccess(true);
            setSuccessMessage("Thank you for your review. Your order is complete!");
           
            const timeId = setTimeout(() => {
                // After 3 seconds set the success value & alert to false
                setSuccess(false);
              }, 2000)
        }
    },
  });


  const [delta, setDelta] = useState({
    ops: [{ insert: "Write your article here..\n" }]
  });

  const [dirtyInnerHTML, setDirtyInnerHTML] = useState("");
  const [text, setText] = useState("");
  const [length, setLength] = useState("");

  const handleTextChange = (content) => {
    //console.log(content);
    formik.values.message = content;
  };

  const requirements_set = order?.requirements;

  return (
        <>
        {!complete &&
        <>
          <div className=" mb-8  min-w-[680px]">
            <h2 className=" font-display text-lg font-bold text-jacarta-400 dark:text-white">
                Leave your Reply 
            </h2>
            <span className="text-jacarta-400 text-sm">
                Rate your hirer experience.
            </span>
        </div>
        
        


        <div className="p-0 ">

        
            <div className="w-full">
                <form onSubmit={formik?.handleSubmit}>



                <div className="flex space-x-7 ">
                    <span className="font-display min-w-[200px]">
                        Communication
                    </span>
                    <Rating
                        name="rating-1"
                        size="large"
                        emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                        value={comm}
                        onChange={(event, newValue) => {setComm(newValue);}}
                    />
                </div>
                <div className="flex space-x-7 mt-1">
                    <span className="font-display min-w-[200px]">
                        Quality 
                    </span>
                   
                    <Rating
                        name="rating-2"
                        size="large"
                        emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                        value={qual}
                        onChange={(event, newValue) => {setQual(newValue);}}
                    />
                </div>
                <div className="flex space-x-7 mt-1 mb-8">
                    <span className="font-display min-w-[200px]">
                        Recommend to a friend?
                    </span>
                    <Rating
                        name="rating-3"
                        size="large"
                        emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                        value={rec}
                        onChange={(event, newValue) => {setRec(newValue);}}
                    />
                </div>

                    {warning&& <Alert severity="warning">{warningMessage}</Alert> }
                    {success&& <Alert severity="success">{successMessage}</Alert> }

                    <div className="flex space-x-7 w-full">
                        <div className="mb-2 w-full text-left">
                            <Editor key="editor" onChange={handleTextChange} value={formik.values.message} />

                        </div>
                    </div>
      
                    <div className="flex w-full justify-end">
                        <button
                            className="bg-accent  hover:bg-accent-dark rounded-full py-3 px-8 text-center font-semibold text-white transition-all">
                            Send Reply
                        </button>
                    </div>
                    </form>
                    {/*<Pre json={formik.values} />*/}
            </div>      
        </div>


    </>
    }
     {complete &&
    <>
        <div className="w-full text-center pt-24 pb-48 min-w-[680px]">
            <CheckCircleOutlinedIcon className="w-24 h-24 text-jacarta-200"/>
            
            <span className="block font-display text-lg mt-6 text-jacarta-200">You have reviewed and completed this order.</span>
        </div>
    </>
    }
    </>
   
  );
};

export default ReviewAdd;
